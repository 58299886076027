import {useEffect, useState} from "react";
import {IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps} from "../../../lib/types/components";
import {FIXED_DOWNLOAD_BUTTON_TYPE, GET_APP_FB_URL} from "../../../lib/utils/constants/GlobalConstants";
import {currentPageURL, isMobile} from "../../../lib/utils/constants/PagesConstants";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import styles from "./FloatingDownloadButtonComponentWithVideo.module.css";
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const FloatingDownloadButtonComponentV2 = (props: IFloatingDownloadButtonComponentProps) => {

    const {
        apkUrl,
        isApkDownloading,
        fixedDownloadButtonData,
        isUtmHotstar,
        blinkTextDownload,
        setIsApkDownloading,
        setIsToastOpen,
        sendAnalytics,
        abTestingData,
        appVersions,
    } = props;

    const isGetAppFb: boolean = currentPageURL === GET_APP_FB_URL;
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);

    //function to toggle fixed download button popup
    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true)
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false)
        }
    }

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {

        window.addEventListener("scroll", onScroll);
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: apkUrl,
        isApkDownloading: isApkDownloading,
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        sendAnalytics: sendAnalytics,
        appVersions,
        abTestingData,
    }

    return (
        !isGetAppFb && blinkTextDownload ?
            <div
                className={multipleClassName("floatingDownloadButtonContainer", styles.floatingDownloadButtonContainer)}
                style={scrollPositionGame ? {bottom: "0px"} : {bottom: "-180px"}}
            >
                <p className={styles.floatingDownloadButtonText}>{blinkTextDownload}</p>
                <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
            </div>
            :
            <></>
    )
}

export default FloatingDownloadButtonComponentV2;